import React, { ReactElement } from "react"
const styles = require("./index.module.css")

function LocationsContainer(): ReactElement {
  return (
    <div className={"py-16 " + styles.wrapper}>
      <div className="max-w-1366 mx-auto ">
        <div className="flex flex-col">
          <div className="text-center md:text-justify mb-8 md:mb-0">
            <h3 className="text-2xl font-bold">Travelling to UK?</h3>
          </div>
          <div className="w-full flex  justify-center pt-4  flex-wrap">
            <div className="w-full md:w-3/4 px-8 md:px-0">
              <h5 className="text-md text-center md:text-justify">
                With over 420 branches across the UK, we aim to have a branch
                conveniently located close to you. If you can’t get to a branch,
                we will come and collect you. You can hire our cars in over 70
                countries, we have over 7,600 branches worldwide. With all these
                branches, we endeavour to always have a vehicle of your choosing
                close to you. Our UK branches have fleets of cars ranging from
                smaller economy cars to larger SUVs and people carriers. Book
                your hire car online for our cheapest prices.
              </h5>
            </div>
            <div className="w-full md:w-1/4 flex justify-center md:justify-start px-4 pt-4 md:pt-0">
              <img src={require("@images/map.png")} className="w-64" />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-16">
          <div className="text-center md:text-justify mb-8 md:mb-0">
            <h3 className="text-2xl font-bold">Car Hire in the UK.</h3>
          </div>
          <div className="w-full flex  justify-center pt-4  flex-wrap">
            <div className="w-full  px-8 md:px-0">
              <h5 className="text-md text-center md:text-justify">
                The United Kingdom is a collection of countries with
                breath-taking sceneries connected by a great network of
                motorways and roads. The variety of bustling cities, quaint
                villages and picturesque Areas Of Natural Beauty (AONB) will
                make you want to drive from one landmark to the next. If you
                want to tackle the Scottish Highlands, bask in the sun of
                Cornwall’s coves or need a car for exploring Northern Ireland –
                make each journey go smooth and easy by renting your car with
                Enterprise. With over 420 branches across the UK, you can find
                us in most airport terminals at our convenient counters where
                you can pick your rental up, or let our agent help you find what
                you need. We also have branches in each of the capitals and in
                most cities, so we are able to help you with car hire in London
                and even in smaller towns.
              </h5>
            </div>
          </div>
        </div>
        <div className="flex  mt-16 flex-wrap text-justify">
          <div className="w-full md:w-1/2 p-4">
            <h3>Wide Range of Vehicles for Hire</h3>
            <h5>
              With one of the world’s biggest rental car fleets, we can help you
              find the ideal car for hire in the UK, no matter what your plans
              and needs are. Whether you’re planning a family road trip and
              you’re after a seven seater car for hire, or you require a simple
              economy car rental to get you from A to B, we’ll help get you on
              the road. We take into account the little nuances that make
              driving a personal experience, so we offer cars with petrol,
              diesel or hybrid powertrains. If you prefer automatics to manuals,
              we can help you with that too. Get a quote above and pick out your
              perfect rental. In need of extra equipment for your rental car? We
              can provide GPS systems, child boosters and seats, as well as
              additional drivers as optional add-ons for your convenience. Look
              at our fleet page to discover the variety of cars we offer in the
              UK.
            </h5>
          </div>
          <div className="w-full md:w-1/2  p-4">
            <h3>Van Hire in the UK</h3>
            <h5>
              When extra cargo space is required, our fleet will definitely have
              the perfect rental van to cater towards your needs. Whether you
              are moving house across the UK, or you need to transport some
              building supplies for your dream conservatory. Our van sizes start
              with the short wheelbase models, and end on the spacious Luton cab
              vans. To make sure your journeys are comfortable, to ensure great
              fuel economy and that driving into city centres is easy, our fleet
              has the latest van models – meaning Ultra Low Emission Zones
              (ULEZ) in places like London are nothing to worry about.
            </h5>
          </div>
        </div>
        <div className="flex  mt-16 flex-wrap text-justify">
          <div className="w-full md:w-1/2 p-4">
            <h3>Cheap Car Hire in the UK</h3>
            <h5>
              If you need a cheap hire car for your stay in the UK, you are in
              the right place. By booking on our Enterprise Rent-A-Car website
              using the Pre-Pay service, you will get the cheapest quote for any
              of our vehicles. We are also proud to be a Which? Recommended
              Provider, meaning you get exceptional customer service on top of
              the great value for your money. Take a look at our current deals
              and see how else we can help you save. Do you hire cars
              frequently? You could benefit from joining the Enterprise Plus
              loyalty membership scheme and earn redeemable points for eligible
              rentals, to get free car hire in for the future.
            </h5>
          </div>
          <div className="w-full md:w-1/2  p-4">
            <h3>Airport Car Hire in the UK</h3>
            <h5>
              When you land at a major airport, you can find us at one of our UK
              airport terminal branches. If you fly into any of the London
              airports, we are able to help get you on the road in Heathrow,
              Luton, Stansted and Gatwick. Our cars and vans are usually a short
              walk away from the terminals, but when the rental car parks are
              further than a stroll, there are shuttles to take you there. We
              understand people value flexibility, especially when some flights
              are outside of normal business hours. That’s why we offer After
              Hours Service that allows you to drop your rental off to an
              Enterprise parking bay, even when we are not there. Just ask one
              of our agents where the key drop-off box is and get on your flight
              stress-free.
            </h5>
          </div>
        </div>
      </div>
      <div className="bg-gray-300">
        <div className="max-w-1366 mx-auto">
          <div className={"my-16 " + styles.fbox}>
            <div className="flex flex-wrap ">
              <div className=" w-full md:w-1/3  p-4 text-center flex items-stretch">
                <div className=" bg-gray-200 p-4">
                  <img src={require("@images/location_ill_one.jpg")} />
                  <h4 className="text-lg mt-2">
                    Need to drop off in a different location?
                  </h4>
                  <h4 className="text-xl font-bold ">One Way Car Hire</h4>
                  <h5 className="text-md mt-4 text-justify">
                    Looking to hire a car for a one-way trip? No problem!
                    Enterprise Rent-A-Car offers easy and convenient one-way car
                    rentals from most of its 10,000 locations in 90+ countries.
                    Choose from a great selection of vehicles, including
                    economy, saloons, SUVs, people carriers and vans.
                  </h5>
                </div>
              </div>
              <div className=" w-full md:w-1/3  p-4 text-center flex items-stretch">
                <div className=" bg-gray-200 p-4">
                  <img src={require("@images/location_ill_pickdrop.jpg")} />
                  <h4 className="text-lg mt-2">
                    Need help picking up and returning car?
                  </h4>
                  <h4 className="text-xl font-bold ">
                    Free Pick Up and Drop Off Service
                  </h4>
                  <h5 className="text-md mt-4 text-justify">
                    Our free pick-up & drop-off service is available at
                    non-airport branches and during normal business hours. To
                    schedule your pick-up time or make additional arrangements,
                    ask for this when your rental branch calls to confirm your
                    reservation.
                  </h5>
                </div>
              </div>
              <div className=" w-full md:w-1/3  p-4 text-center flex items-stretch">
                <div className=" bg-gray-200 p-4">
                  <img src={require("@images/location_ill_long.jpg")} />
                  <h4 className="text-lg mt-2">
                    Need a car for an extended time?
                  </h4>
                  <h4 className="text-xl font-bold ">
                    Long Term Car Hire Service
                  </h4>
                  <h5 className="text-md mt-4 text-justify">
                    Looking for a flexible alternative to a traditional rental
                    or lease agreement? If so, we've got a programme for you —
                    it's our Month-or-More℠ Plan. We've set up dedicated
                    long-term branches throughout UK to provide our customers
                    one-on-one attention. Just let us know when and where you
                    need to rent a vehicle, and we'll take care of the rest.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationsContainer
